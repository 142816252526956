import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { Section, TextContainer } from "../styles/container"
import { H1 } from "../styles/headings"
import { LongText } from "../styles/text"

const query = graphql`
  {
    text: airtableTexts(data: { id: { eq: "datenschutz" } }) {
      data {
        headline
        text1 {
          childMdx {
            rawBody
          }
        }
        text2 {
          childMdx {
            rawBody
          }
        }
      }
    }
  }
`
const PrivacyPage = () => {
  const data = useStaticQuery(query)
  const cookiebotRef = useRef()
  useEffect(() => {
    const cookiebotContainer = cookiebotRef.current
    const script = document.createElement("script")
    script.setAttribute("id", "CookieDeclaration")
    script.setAttribute(
      "src",
      "https://consent.cookiebot.com/800b6af8-df9d-415a-ba3c-21f21dfdcf30/cd.js"
    )
    script.setAttribute("type", "text/javascript")
    script.setAttribute("async", true)

    cookiebotContainer.appendChild(script)

    return () => {
      if (cookiebotContainer) {
        cookiebotContainer.innerHTML = ""
      }
    }
  }, [])
  const {
    headline,
    text1: {
      childMdx: { rawBody: text1 },
    },
    text2: {
      childMdx: { rawBody: text2 },
    },
  } = data.text.data
  return (
    <Layout>
      <Seo title={headline} />
      <Section paddingTop paddingBottom fluid boxed>
        <TextContainer>
          <LongText>
            <H1>{headline}</H1>
            <ReactMarkdown>{text1}</ReactMarkdown>
            <div ref={cookiebotRef}></div>
            <ReactMarkdown>{text2}</ReactMarkdown>
          </LongText>
        </TextContainer>
      </Section>
    </Layout>
  )
}

export default PrivacyPage
